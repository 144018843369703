@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #fff;
    font-size: 16px;
    font-family: 'IBM Plex Sans Arabic', sans-serif;
    overflow-x: hidden;
    
}


